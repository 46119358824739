<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="lds-ripple-content" v-if="isLoading"><div class="lds-ripple"><div></div><div></div></div></div>
    
    <section class="main-page term-condition-baner">
        <div class="container">
          <div class="row about-us">
            <div class="col-md-8 col-sm-12 col-12">
              <div class="about-us-inner">
                  <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt=""></div>
                  <p v-if="errorMsg !== ''" style="color: red; text-align: center">{{ errorMsg }}</p>
                  <div class="payment-status-text generate-refferalInner" v-if="isShow">
                    <h6> While we are waiting for the REFERRAL CODE to hit your inbox, may we respectfully request your valuable and important feedback to us on <router-link to="/">aanantya.co</router-link> please? We promise to not share your name unless you ask us to share your name along with your feedback, if we share your feedback publicly. When you share with us, what we can do better, where we provided incorrect information or where you felt inadequately supported, or even, what it is that we do really well that might have given you an AHA moment.….you help us improve the site for you as well as other users, and you give us an opportunity to present you with rewards along the way as well. The rewards maybe things like interviewing your favorite personality on <router-link to="/">aanantya.co</router-link> Samskrit TV or getting a Samskrit autograph from a prominent Samskrit language authority for you and so on….Thank you so very much ~ We are most grateful to you and appreciate your input so very much ~</h6>
                    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">FEEDBACK</button>
                  </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Feedback Modal start -->
        <div class="modal dashboard-modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Feedback Modal</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  
                      <div class="mb-3">
                        <textarea id="feedback" name="feedback" rows="5" placeholder="Please type your feedback..." class="form-control" v-model="feedbackContent"></textarea>
                      </div>
                      <p v-if="modalErrorMsg !== ''" style="color: red; text-align: center">{{ modalErrorMsg }}</p>
                      <button type="button" class="btn btn-primary" @click="feedbackSubmit">Submit</button>
                    
              </div>
            </div>
          </div>
        </div>
        <!-- Feedback Modal end -->


      </section>
      </template>
      
      <script>
     import { API } from "@/api/api";
      
      export default {
        data() {
          return {
            isLoading: false,
            isShow: false,
            errorMsg: '',
            token: null,
            feedbackContent: '',
            modalErrorMsg: ''
          };
        },
        created() {
            this.token = this.$route.query.token;
            this.getResponse();
       },
        methods: {
            getResponse(){
                this.isLoading = true;
                API.get(`referral/generateReferral?token=${this.token}`)
                .then(response => {
                    console.log(response, "After call generateReferral code");
                    this.isShow = true;
                    this.isLoading = false;
                }).catch(error => {
                    console.error("fail", error.response.data.message);
                    this.errorMsg = error.response.data.message;
                    this.isLoading = false;
                    });
            },
            feedbackSubmit(){
              // Check if feedbackContent is empty
              if (!this.feedbackContent.trim()) {
                    this.modalErrorMsg = 'Feedback cannot be empty';
                    return; // Prevent submission if feedback is empty
                  }
                const sendData = {
                    token: this.token,
                    feedback: this.feedbackContent
                }
                API.post(`feedback/sendFeedback`, sendData)
                .then(response => {
                    console.log(response, "send feedback data")
                    // Dismiss the modal after submission
                    const modal = document.getElementById('exampleModal');
                      if (modal) {
                        modal.classList.remove('show');
                        modal.style.display = 'none';
                        const backdrop = document.getElementsByClassName('modal-backdrop')[0];
                        if (backdrop) backdrop.remove();
                      }
                      // -------------------------
                    this.$router.push('/login');
                }).catch(error => {
                    console.error("fail", error);
                    this.modalErrorMsg = "Something Wrong....."
                    this.isLoading = false;
                    });

            }
        },
      };
      </script>
      
      