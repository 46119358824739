<!-- eslint-disable no-undef -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="lds-ripple-content" v-if="isLoading">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
  <section class="main-page dashboard-main" :class="{ 'answerPart': $route.query.id !== undefined }">
    <div class="dashboard-inner">
      <div class="mobile-bar" @click="mobileViewOpen" ref="mobileBar">
        <i class="fa-solid fa-bars-staggered"></i>
        <i class="fa-solid fa-xmark"></i>
      </div>
      <div class="dashboard-iner-lft" id="dashboardLftSideBar" ref="dashboardLftSideBar">
        <div class="dashboard-iner-lft-content">
          <div class="dashboard-hder-logo">
            <router-link to="/">
            <img src="assets/images/aanantya-logo.png" alt="">
          </router-link>
          </div>
          <router-link to="/dashboard">
            <div class="dashboard-lft-nwchat">
              <a href="javascript:void(0);"><img src="assets/images/messages.png" alt=""> New Chat</a>
            </div>
          </router-link>
          <div class="chart-history">
            <label>Chat History</label>
            <ul class="list-inline">
              <li v-for="(chatInfo, index) in chatHistory" :key="index">
                <a href="javascript:void(0);" @click="getChatInfo(chatInfo.chatId)"
                  :class="{ 'active': $route.query.id === chatInfo.chatId }">
                  <img src="assets/images/learn.png" alt="" />
                  {{ chatInfo.heading }}
                </a>
              </li>
            </ul>
          </div>

          <div class="acount-setting">
            <div class="acount-details dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="profilePic"><img :src="userInfo.profile_picture" alt=""></span>
              <p>{{ userInfo.firstName }}</p>
              <span><img src="assets/images/acount-bar.png" alt=""></span>
            </div>
            <ul class="dropdown-menu">
              <li>
                <router-link to="/suggestions-instructions">
                  <i class="fa-solid fa-person-chalkboard"></i>
                  Suggestions-Instructions
                </router-link>
              </li>
              <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#change_password" @click="changPassword"
                  href="javascript:void(0);"><i class="fa-regular fa-file-excel"></i>
                  Change password </a></li>
              <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#cancelSubscription"
                  @click="showCancelSubscriptionModal" href="javascript:void(0);"><i class="fa-solid fa-phone"></i>
                  Contact Us </a></li>
              <li><a class="dropdown-item" href="javascript:void(0);" @click="logout"><i
                    class="fa-solid fa-right-from-bracket"></i> Log out</a></li>
            </ul>
          </div>
        </div>
        <div class="modal fade dashboard-modal" :class="{ 'show': isCancelSubscriptionModalVisible }"
          id="cancelSubscription" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                <!-- <h6>Do you want to cancel subscription?</h6> -->
                <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p>
                <div class="cancel-subscription-modal">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label>To</label>
                            <input type="text" placeholder="From..." :value="toEmail" readonly>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label>From</label>
                            <input type="text" placeholder="To..." :value="userInfo.email" readonly>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" placeholder="Subject" v-model="subject">
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                      <div class="form-group">
                        <label>Reason</label>
                        <textarea placeholder="Reasons..." v-model="cancelText"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn close btn-secondary" data-bs-dismiss="modal" @click="closeSubscriptionModal">Cancel</button>
                <button type="button" class="btn btn-primary" @click="cancelSubscriptionRequest">Send</button>
              </div>
            </div>
          </div>
        </div>



        <!-- For cancel subscription request message -->

        <div class="modal fade dashboard-modal" :class="{ 'show': isChangePassword }" id="change_password" tabindex="-1"
          aria-labelledby="change_password" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">

                <div class="form-devider field-type">
                  <label for="old_password" class="form-label">Old Password</label>
                  <input type="password" class="form-control" id="old_password" placeholder="old password"
                    v-model.trim="formData.oldPpassword" />
                  <span v-if="formSubmitted && !formData.oldPpassword">Old Password is required</span>
                </div>

                <div class="form-devider field-type">
                  <label for="new_password" class="form-label">New Password</label>
                  <input type="password" class="form-control" id="new_password" placeholder="new password"
                    v-model.trim="formData.newPpassword" />
                  <span v-if="formSubmitted && !formData.newPpassword">New password is required</span>
                </div>

                <div class="form-devider field-type">
                  <label for="confirm_password" class="form-label">Confirm Password</label>
                  <input type="password" class="form-control" id="confirm_password" placeholder="confirm password"
                    v-model.trim="formData.confirmpassword" />
                  <span v-if="formSubmitted && !formData.confirmpassword">Confirm password is required</span>
                </div>
                <!-- <span style="color: red;">{{error}}</span> -->
                <p class="errmsg" v-if="error !== ''">{{ error }}</p>

              </div>
              <div class="modal-footer">

                <button type="button" class="btn close btn-secondary" data-bs-dismiss="modal"  @click="closePasswordModal">Cancel</button>
                <button type="button" class="btn btn-primary" @click="updateChangepassword">Done</button>
              </div>
            </div>
          </div>
        </div>

        <!-- For cancel subscription request message -->
        <div v-if="showAlert" class="alert">
          {{ alertMessage }}
        </div>
      </div>
      <div class="dashboard-iner-rgt" :class="{ 'chatgpt-main': $route.query.id !== undefined }">
        <div v-if="$route.query.id !== undefined" class="chatWrap" id="chatWrap" ref="chatgptMain" @scroll="handleScroll">
          <span v-for="(chatById, index) in chatHistoryById" :key="index">
            <div class="chartbot-header" v-if="chatById.showQuery">
              <h4>{{ chatById.query }}</h4>
            </div>
            <div class="chartbot-answer-section">
              <!-- <p class="answerContent">{{ chatById.responseJson.Sanskrit }}</p> -->
              <p class="answerContent" v-html="formatResponse(chatById.responseJson.primary)"></p>
              <p v-if="chatById.responseJson.question"><b>{{ chatById.responseJson?.question?.primary }}</b></p>
              <div class="translateLanguage">
                <button v-if="chatById.responseJson.english" type="button" @click="showEnglish(index)">Translate</button>
                <button v-else type="button" @click="showTranslate(index)">Translate</button>
              </div>
              <!-- Part English -->
              <p v-if="chatById.isShowEng" v-html="formatResponse(chatById.responseJson.english)"></p>
              <p v-if="chatById.isShowEng && chatById.responseJson?.question"><b>{{ chatById.responseJson?.question?.english }}</b></p>
              <!-- Part Third Language -->
              <p v-if="chatById.isShowEng && chatById.responseJson?.third_language" v-html="formatResponse(chatById.responseJson?.third_language)"></p>
              <p v-if="chatById.isShowEng && chatById.responseJson.question?.third_language"><b>{{ chatById.responseJson?.question?.third_language }}</b></p>
              <!-- <div class="moreSection" v-if="!chatById.moreDetialsClicked">
                          <a href="javascript:void(0);" @click="readMore(chatById, index)">Continue Conversation...</a>
                        </div> -->

           <!-- This part for feedback section -->
                        <hr>
            <div class="feedback-section" :class="{'feedback-desable': chatById.isFeedbackGiven}">
                <div class="likeSec" :style="{ 'pointer-events': chatById.isFeedbackGiven ? 'none' : 'auto' }" data-bs-toggle="modal" data-bs-target="#likeFeedback">
                  <a href="javascript:void(0)" @click="sendFeedback(chatById,index, true)" :class="{'active': chatById.feedbackType}"><i class="fa-solid fa-thumbs-up"></i></a>
                </div>
                <div class="dislikeSec" :style="{ 'pointer-events': chatById.isFeedbackGiven ? 'none' : 'auto' }" data-bs-toggle="modal" data-bs-target="#likeFeedback">
                  <a href="javascript:void(0)" @click="sendFeedback(chatById,index, false)" :class="{'active': chatById.feedbackType === false}"><i class="fa-solid fa-thumbs-down"></i></a>
                </div>
               <!-- <div class="audio-section">
                  <a href="javascript:void(0)" @click="toggleAudio(index)">
                    <i v-if="chatById.isPlaying" class="fa-solid fa-pause"></i>
                    <i v-else class="fa-solid fa-play"></i>
                  </a>
                </div> -->
            </div>
            </div>
            
          </span>
        </div>
        <div class="chartbot-answer-section-choose"></div>
        <div class="question-option" v-if="$route.query.id === undefined">
          <div id="questionoption" class="owl-carousel owl-theme">
            <div class="item">
              <a href="javascript:void(0);" @click="handleQueryClick">Vedas</a>
            </div>
            <div class="item">
              <a href="javascript:void(0);" @click="handleQueryClick">Upanishads</a>
            </div>
            <div class="item">
              <a href="javascript:void(0);" @click="handleQueryClick">Mahabharata</a>
            </div>
            <div class="item">
              <a href="javascript:void(0);" @click="handleQueryClick">Ramayana</a>
            </div>
          </div>
        </div>
        <div class="question-type">
          <div class="custom-select">
            <select v-model="selectedLangOption" id="selectBox">
              <option value="null">Default Language</option>
              <option v-for="(lang, index) in languages" :key="index" :value="lang.languageName">
                {{ lang.languageName }}
              </option>
            </select>
            <p><i class="fa-solid fa-circle-info"></i> Please choose optional second language choice to translate sanskrit answer into</p>
          </div>
          <!-- <input type="text" placeholder="Type..." v-model="inputQuery" @keyup.enter="sendQuery"> -->
          <textarea placeholder="Type ..." v-model="inputQuery" @keyup.enter="sendQuery"></textarea>
          <button type="submit" class="btn" @click="sendQuery" :disabled="inputQuery === ''">Ask Now</button>
        </div>
      </div>
    </div>
  </section>
  <!-- Like modal feedback start -->
<!-- Modal -->
<div class="modal dashboard-modal fade" id="likeFeedback" tabindex="-1" aria-labelledby="likeFeedbackLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="likeFeedbackLabel">Feedback</h1>
      </div>
      <div class="modal-body">
        <textarea placeholder="Comments..." class="feedback-area form-control" v-model="feedBackComment"></textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn close btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" @click="feedBackApiCall()">Submit</button>
      </div>
    </div>
  </div>
</div>
  <!-- Like modal feedback end -->

  <!-- If Token Exhausted then modal is coming -->
  <div class="tokenModal" v-if="isTokenExhaustedModal">
      <!-- Modal content -->
      <div class="modal-content tokenModal-content">
        <span class="closeTokenModal" @click="closeTokenModal">&times;</span>
        <div class="modal-header"><h1 class="modal-title fs-5">{{ additionalPkgInfo.packageName }}</h1></div>
        <div class="token-body-cntn">
          <p v-html="additionalPkgInfo.description"></p>
          <div class="token-price-btn">
            <h3>{{ additionalPkgInfo.currency }} {{ additionalPkgInfo.price }}</h3>
            <button class="btn" @click="paymentAdditional(additionalPkgInfo)">Pay</button>
          </div>
        </div>
        <!-- You can customize the modal content as needed -->
      </div>
    </div>
<!-- Token Exhausted modal end -->
<afterLoginChatbot></afterLoginChatbot>

</template>



<script>
import { API } from "@/api/api";
import afterLoginChatbot from '../views/beforeLoginChatbot.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Dashboard",
  components: {
            // eslint-disable-next-line vue/no-unused-components
            afterLoginChatbot
        },
  data() {
    return {
      formData: {
        newPpassword: '',
        oldPpassword: '',
        confirmpassword: '',

      },
      error:'',
      errorMsg:'',
      userInfo: null,
      chatHistory: [],
      inputQuery: '',
      cancelText: '',
      chatHistoryById: [],
      isLoading: false,
      showAlert: false,
      alertMessage: '',
      isCancelSubscriptionModalVisible: false,
      queryIdValue: null,
      page: 1,
      limit: 10,
      isChangePassword: false,
      formSubmitted: false, // Track form submission status
      languages : [],
      selectedLangOption : null,
      toEmail: 'care@aanantya.co',
      subject: '',
      feedBackObj: {
        // eslint-disable-next-line no-undef
         chatIndex: Number,
         // eslint-disable-next-line no-undef
         chatInfo: Object,
          // eslint-disable-next-line no-undef
          feedbackType: String
      },
      feedBackComment: null,
      isFeedbackCommentModalVisible: false,
      isTokenExhaustedModal: false,
      additionalPkgInfo: Object,
      audioElements: []
    };
  },
  async created() {
    // Accessing localStorage when the component is created
    await this.getUserInfoFromLocalStorage();
    this.getChatHistory();
    this.getLanguage();
    console.log(this.$route.query.id, "routeInfo")
    const routeInfo = this.$route.query.id;
    if (routeInfo !== undefined || routeInfo !== '') {
      this.getChatInfo(this.$route.query.id);
    }
  },
  methods: {

toggleAudio(index) {
  const audioResponse = this.chatHistoryById[index].audioResponse;
  if(audioResponse === undefined || audioResponse === null){
      this.fetchAudio(index);
  }else{
    this.controleAudio(index);
  }
},
 fetchAudio(index){
  this.isLoading = true;
  const chatId = this.$route.query.id;
      var head = {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("Token"))
        }
      };
      const sendData = {
        chatId: chatId,
        queryId: this.chatHistoryById[index].queryId,
      }
      API.post('chat/getaudioresponse', sendData, head)
          .then(response => {
            if (response.data && response.data.data && response.data.data.audioResponse) {
              this.chatHistoryById[index].audioResponse = response.data.data.audioResponse;
              this.chatHistoryById[index].isPlaying = false;
            //    // Update the object at the specified index to include the isPlaying property
            // this.$set(this.chatHistoryById[index], 'audioResponse', response.data.data.audioResponse);
            // this.$set(this.chatHistoryById[index], 'isPlaying', false);
            this.isLoading = false;
               this.controleAudio(index);
            } else {
              console.error('Invalid response from API');
            }
          })
          .catch(error => {
            this.isLoading = false;
            if (error.response.status == 402) { 
              this.showAlertMessage(error.response.data.message);
              setTimeout(() => {
                this.logout(); 
            }, 5000);
            } else {
              console.error('Error fetching audio source:', error);
            this.showAlertMessage('Error fetching audio source !!');
            }
            
          });
  },
  controleAudio(index){
    const audioResponse = this.chatHistoryById[index].audioResponse;
// Check if an audio element already exists for this index
if (!this.audioElements[index]) {
    // If not, create a new audio element
    this.audioElements[index] = new Audio(audioResponse);
    
    // Add event listener for playback end
    this.audioElements[index].addEventListener('ended', () => {
      this.chatHistoryById[index].isPlaying = false;
    });
  }

  const audioElement = this.audioElements[index];

  if (audioElement.paused) {
    audioElement.play();
    this.chatHistoryById[index].isPlaying = true;
  } else {
    audioElement.pause();
    this.chatHistoryById[index].isPlaying = false;
  }
},
  
    closeSubscriptionModal(){
      this.cancelText = '';
      this.subject = '';
      this.errorMsg = '';
    },

    getLanguage(){
      API.get("generic/languages")
        .then(response => {
          console.log(response, "Get Language list....");
          this.languages = response.data.data;
        }).catch(error => {
          console.error("fail", error);
        });
    },

    updateChangepassword() {
      this.isLoading = true;
      this.formSubmitted = true; // Set form submitted status
      console.log('Form submitted:', this.formData.confirmpassword);

      if (this.formData.oldPpassword =='') {
        this.error = "Old password required";
        return;

      }

      if (this.formData.newPpassword =='') {
        this.error = "New password required";
        return;

      }

      if (this.formData.confirmpassword=='') {
        this.error = "Confirm password required";
        return;

      }

      if (this.formData.confirmpassword != this.formData.newPpassword) {
        this.isLoading = false;
        this.error = "Confirm password don't match ";
        return;

      } else {
        console.log(this.formData.value)
        const passwordUpdate = {
          old_password: this.formData.oldPpassword,
          new_password:this.formData.newPpassword
        }
        API.post("auth/updatepassword", passwordUpdate)
          .then(response => {
            console.log(response, "After API call after query post");
            console.log(response);
            // this.showAlertMessage('Your ');
            this.isLoading = false;
            this.isChangePassword=false;
            const backdropElement = document.querySelector('.modal-backdrop');
            if (backdropElement) {
              backdropElement.remove();
            }
            this.showAlertMessage(response.data.message);
            this.cancelText = '';
          }).catch(error => {
            console.error("fail", error);
            this.isLoading = false;
            // this.isChangePassword=false;
            this.error = error.response.data.message;
            // const backdropElement = document.querySelector('.modal-backdrop');
            // if (backdropElement) {
            //   backdropElement.remove();
            // }
          });
      }
    },

    closePasswordModal(){
      this.isLoading = false;
      this.formSubmitted=false;
      console.log(this.formSubmitted);
    },

    // cancel subscription with reason 
    async cancelSubscriptionRequest() {
      if (this.cancelText && this.subject) {
        this.isCancelSubscriptionModalVisible = false;
        this.isLoading = true;
        const reasontext = {
          content: this.cancelText,
          subject: this.subject
        }
        API.post("support/requestcancel", reasontext)
          .then(response => {
            console.log(response, "After API call after query post");
            console.log(response);
            // this.showAlertMessage('Your ');
            this.isLoading = false;
            const backdropElement = document.querySelector('.modal-backdrop');
            if (backdropElement) {
              backdropElement.remove();
            }
            this.showAlertMessage(response.data.message);
            this.cancelText = '';
          }).catch(error => {
            console.error("fail", error);
            this.isLoading = false;
            this.errorMsg = 'Something went wrong';
            const backdropElement = document.querySelector('.modal-backdrop');
            if (backdropElement) {
              backdropElement.remove();
            }
          });
      } else if(!this.subject && this.cancelText) {
        this.errorMsg = 'Please insert a subject';
        return;
      } else if(!this.cancelText && this.subject) {
        this.errorMsg = 'Please insert a reason';
        return;
      } else {
        this.errorMsg = 'Please insert a subject and reason ';
        return;
      }


    },

    async getUserInfoFromLocalStorage() {
      // Retrieve user info from localStorage
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        // If user info exists in localStorage, set it to the userInfo data property
        this.userInfo = JSON.parse(userInfo);
      } else {
        // Handle the case where user info is not available in localStorage
        console.log('User information not found in localStorage.');
      }
    },
    getChatHistory() {
      // Retrieve chat info from API
      this.isLoading = true;
      var head = {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("Token"))
        }
      };
      API.get("chat/list", head)
        .then(response => {
          console.log(response, "After API call");
          this.chatHistory = response.data.data;
          this.isLoading = false;
        }).catch(error => {
          console.error("fail", error);
          this.isLoading = false;
          if (error.response.status == 402) { 
              this.showAlertMessage(error.response.data.message);
              setTimeout(() => {
                this.logout(); 
            }, 5000);
            } 
        });
    },
    getChatInfo(chatInfo) {
      if (chatInfo != undefined) {
        // For Mobile

        const myElement = this.$refs.dashboardLftSideBar;
        if(myElement) myElement.classList.remove('show');
        
        const mobileBar = this.$refs.mobileBar;
        if(mobileBar) mobileBar.classList.remove('show');

        //////////////
        this.page = 1;
        this.isLoading = true;
        this.chatHistoryById = [];
        this.$router.push({ path: 'dashboard', query: { id: `${chatInfo}` } });
        // get chat response with id
        const chatId = chatInfo;
        var head = {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("Token"))
          }
        };
        API.get(`chat/list/messages?chatId=${chatId}&page=${this.page}&limit=${this.limit}`, head)
          .then(response => {
            console.log(response, "After chat response API call");
            // this.chatHistoryById = response.data.data;
            // Loop through each item in the chat history array and add the isPlaying property
              this.chatHistoryById = response.data.data.map(item => {
                return { ...item, isPlaying: false };
              });
            setTimeout(() => {
              this.scrollToBottom();
            }, 500);
          }).catch(error => {
            console.error("fail", error);
            this.isLoading = false;
            if (error.response.status == 402) { 
              this.showAlertMessage(error.response.data.message);
              setTimeout(() => {
                this.logout(); 
            }, 5000);
            } 
          });
      }

    },
    scrollToBottom() {
      // Assuming this element is within a container that needs scrolling
      // event.preventDefault();
      // const container = this.$refs.chatgptMain;
      // console.log(container, "container=====")
      // if (container) {
      //   const scrollTop = container.scrollTop;
      //   console.log("Vertical scroll position:", scrollTop);
      //   container.scrollTop = container.scrollHeight;
      // }
      // console.log(container.scrollHeight);
      // window.scrollTo(0, container.scrollHeight);
      var objDiv = document.getElementById("chatWrap");
      objDiv.scrollTop = objDiv.scrollHeight;
      console.log(objDiv, 'objDiv');
      this.isLoading = false;
    },



    // async sendChatQuery(inputChatQuery, queryId) {
    async sendChatQuery(inputChatQuery) {
      this.isLoading = true;
      const chatObj = {
        chatId: this.$route.query.id === undefined ? null : this.$route.query.id,
        query: inputChatQuery,
        // queryId: queryId,
        // third_language: this.selectedLangOption
      }
      // API.post("chat/getqueryresponse", chatObj)
      API.post("chat/getqueryprimaryresponse", chatObj)
        .then(response => {
          console.log(response, "After API call after query post");
          const returnData = response.data.data
          //Adds returnData at the 0 index
          if (response.data.data.isNewChat) {
            this.getChatInfo(returnData.chatId);
            this.chatHistory.unshift(returnData);
            this.$router.push({ path: 'dashboard', query: { id: `${returnData.chatId}` } });
          }
          else this.chatHistoryById.push(returnData);
          //scrolldown method call after chat query submit
          this.inputQuery = '';
          setTimeout(() => {
            this.scrollToBottom();
          }, 1000);
        }).catch(error => {
          console.error("fail", error.message);
          this.isLoading = false;
          if (error.response.status == 402) { 
              this.showAlertMessage(error.response.data.message);
              setTimeout(() => {
                this.logout(); 
            }, 5000);
            } else {
          this.showAlertMessage(error.response.data.message);
          if(error.response && error.response.data.data && error.response.data.data.isTokenExhausted)
          this.openTokenModal();  //if token is end
            }
        });
    },
    handleQueryClick(event) {
      // Access the text content of the clicked element and log it to the console
      //chatID and queryIdValue will be null for these new chat dashboard buttons
      this.sendChatQuery(event.target.textContent, null);
    },
    sendQuery() {
      //queryIdValue will always be null, when new query is written in the chat box
      if(this.inputQuery !== '')
      this.sendChatQuery(this.inputQuery, null);
    },
    cancelSubscription() {
      var head = {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("Token"))
        }
      };
      API.post("subscription/cancel", head)
        .then(response => {
          console.log(response, "After cancel subscription API call");
          const backdropElement = document.querySelector('.modal-backdrop');
          if (backdropElement) {
            backdropElement.remove();
          }
          this.isCancelSubscriptionModalVisible = false;
          this.showAlertMessage(response.data.message);
        }).catch(error => {
          console.error("fail", error);
          console.log(error.response.status, "subscription request if fail");
          this.isCancelSubscriptionModalVisible = false;
          const backdropElement = document.querySelector('.modal-backdrop');
          if (backdropElement) {
            backdropElement.remove();
          }
          if (error.response.status == 402) { this.logout(); }
        });
    },
    showCancelSubscriptionModal() {
      console.log("show modal....")
      this.cancelText = '';
      this.subject = '';
      this.errorMsg = '';
      this.isCancelSubscriptionModalVisible = true;
    },

    showAlertMessage(reaponse) {
      // this.error=reaponse;
      this.alertMessage = reaponse;
      this.showAlert = true;

      setTimeout(() => {
        this.showAlert = false;
      }, 5000); // Hide alert after 3 seconds
    },
    logout() {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("Token");
      window.location.href = "/login";
    },

    changPassword() {
      console.log('welcome');
      this.formData.confirmpassword='';
      this.formData.newPpassword='';
      this.formData.oldPpassword='';
      this.isChangePassword = true;
      this.formSubmitted=false;
      this.error='';
    },
    showEnglish(indexToUpdate) {
      // Key-value pair to add or update
      let keyToUpdate = 'isShowEng';
      let valueToUpdate = true;
      // Check if the object at the specified index exists
      if (this.chatHistoryById[indexToUpdate]) {
        // Add or update the key-value pair in the object
        this.chatHistoryById[indexToUpdate][keyToUpdate] = valueToUpdate;
      }
    },
    showTranslate(indexToUpdate){
      this.isLoading = true;
      console.log(this.chatHistoryById[indexToUpdate], "chatIndex", this.$route.query.id);
      const chatId = this.$route.query.id;
      var head = {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("Token"))
        }
      };
      const sendData = {
        chatId: chatId,
        queryId: this.chatHistoryById[indexToUpdate].queryId,
        third_language: this.selectedLangOption
      }
      API.post(`chat/getquerytranslateresponse`, sendData, head)
        .then(response => {
          console.log(response.data.data, "After API call get to translate response");
          // update the responses
              this.chatHistoryById[indexToUpdate].responseJson = response.data.data
              this.showEnglish(indexToUpdate);
          this.isLoading = false;
        }).catch(error => {
          console.error("fail", error.message);
          this.isLoading = false;
          if (error.response.status == 402) { 
              this.showAlertMessage(error.response.data.message);
              setTimeout(() => {
                this.logout(); 
            }, 5000);
            } else {
          this.showAlertMessage(error.response.data.message);
          if(error.response && error.response.data.data && error.response.data.data.isTokenExhausted)
          this.openTokenModal();  //if token is end
            }
        });

    },
    async readMore(chatInfo, index) {
      console.log(chatInfo, "chatInfo==")
      this.queryIdValue = chatInfo.queryId;
      await this.sendChatQuery(chatInfo.query, this.queryIdValue);
      this.chatHistoryById[index].moreDetialsClicked = true;
    },
    handleScroll() {
      // Check if the user has scrolled to the top
      console.log(this.$refs.chatgptMain.scrollTop, "scrollheight")
      if (this.$refs.chatgptMain.scrollTop === 0) {
        // Load more messages (you can replace this with your actual logic to fetch more messages)
        this.loadMoreMessages();
      }
    },
    loadMoreMessages() {
      this.page++;
      this.loading = true;
      const chatInfo = this.$route.query;
      console.log(chatInfo, "chatIdchatId")
      var head = {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("Token"))
        }
      };
      API.get(`chat/list/messages?chatId=${chatInfo.id}&page=${this.page}&limit=${this.limit}`, head)
        .then(response => {
          console.log(response, "After load more chat response API call");
          // Append new messages to the chatMessages array
          if (response.data.data.length > 0) {
            this.chatHistoryById = [...response.data.data, ...this.chatHistoryById];
            // Automatically scroll to the bottom after the next DOM update
            this.$nextTick(() => {
              const container = this.$refs.chatgptMain;
              container.scrollTop = 300;
            });
          }

          this.loading = false;
        }).catch(error => {
          console.error("fail", error);
          this.isLoading = false;
          if (error.response.status == 402) { 
              this.showAlertMessage(error.response.data.message);
              setTimeout(() => {
                this.logout(); 
            }, 5000);
            } 
        });
    },
    //html tag convert code...
    formatResponse(text) {
      // Replace <br/> with new lines
      if(text) {
        const formattedText = text.replace(/<br\s*\/?>/g, '<br/>').replace(/<br\/?>/g, '<br/>');
      // Use v-html to render the HTML
      return formattedText;
      }
      
    },
    sendFeedback(chatInfo, index, feedType){
      this.feedBackObj = {
        chatIndex : index,
        chatInfo : chatInfo,
        feedbackType:feedType 
      }
      this.feedBackComment = '';
      this.isFeedbackCommentModalVisible = true;
    },
    feedBackApiCall(){
      this.loading = true;
      var head = {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("Token"))
        }
      };
      const sendData = {
        queryId: this.feedBackObj.chatInfo.queryId,
        feedbackType: this.feedBackObj.feedbackType,
        feedbackContent: this.feedBackComment
      }
      API.post(`chat/givefeedback`, sendData, head)
        .then(response => {
          console.log(response.data.data, "After API call send the feedback response");

          this.chatHistoryById[this.feedBackObj.chatIndex].feedbackType = this.feedBackObj.feedbackType;
          this.chatHistoryById[this.feedBackObj.chatIndex].isFeedbackGiven = true;
          this.isFeedbackCommentModalVisible = false;
         const modal = document.getElementById('likeFeedback');
         modal.classList.remove('show');
         modal.classList.add('hidden');
         this.removeModalBackdrop();
             
          this.isLoading = false;
        }).catch(error => {
          console.error("fail", error);
          this.isLoading = false;
          if (error.response.status == 402) { 
              this.showAlertMessage(error.response.data.message);
              setTimeout(() => {
                this.logout(); 
            }, 5000);
            } 
        });
     
    },
    removeModalBackdrop(){
      const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.parentNode.removeChild(backdrop);
    }
    },
    mobileViewOpen(){
      const myElement = this.$refs.dashboardLftSideBar;
      myElement.classList.toggle('show');
      const mobileBar = this.$refs.mobileBar;
      mobileBar.classList.toggle('show');
    },
    openTokenModal(){
      this.inputQuery = '';
      this.isTokenExhaustedModal = true;
      var head = {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("Token"))
        }
      };
      API.get(`packages/list/additional`, head)
          .then(response => {
            console.log(response, "Additional pakage info");
            this.additionalPkgInfo = response.data.data[0];
          }).catch(error => {
            console.error("fail", error);
          });

    },
    closeTokenModal(){
      this.isTokenExhaustedModal = false;
    },
    paymentAdditional(pkgInfo){
      this.isLoading = false;
      const sendData = {
        packageSlug: pkgInfo.packageSlug
      }
      var head = {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("Token"))
        }
      };
      API.post(`subscription/purchaseadditionaltoken`,sendData, head)
          .then(response => {
                console.log(response, "Additional pakage info");
                const redirectUrl = response.data.data;
                this.isLoading = false;
                window.location.href = redirectUrl;
          }).catch(error => {
            console.error("fail", error);
          });
    }

  }
};
</script>
