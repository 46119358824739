<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="lds-ripple-content" v-if="isLoading"><div class="lds-ripple"><div></div><div></div></div></div>
    <!-- <section class="main-page subscription-page payment-page failure">
      <div class="container">
        <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt=""></div>
        <div class="payment-status-text">
          <h4><i class="fa-solid fa-xmark"></i> Cancel your Subscription for next month.. !</h4>
          <router-link to="/login">
            <button type="button">Back to login</button>
          </router-link>
        </div>
    </div>
    </section> -->

    <section class="main-page term-condition-baner">
        <div class="container">
          <div class="row about-us">
            <div class="col-md-8 col-sm-12 col-12">
              <div class="about-us-inner">
                  <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt=""></div>
                  <p v-if="errorMsg !== ''" style="color: red; text-align: center">{{ errorMsg }}</p>
                  <div class="payment-status-text generate-refferalInner" v-if="isShow">
                    <h6> Even though you opted out of generating a referral code this time, we're still sending one your way. Your subscription matters to us! Stick around for more exclusive perks and updates tailored just for you. ~</h6>
                    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">FEEDBACK</button>
                  </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Feedback Modal start -->
        <div class="modal dashboard-modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Feedback Modal</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  
                      <div class="mb-3">
                        <textarea id="feedback" name="feedback" rows="5" placeholder="Please type your feedback..." class="form-control" v-model="feedbackContent"></textarea>
                      </div>
                      <p v-if="modalErrorMsg !== ''" style="color: red; text-align: center">{{ modalErrorMsg }}</p>
                      <button type="button" class="btn btn-primary" @click="feedbackSubmit">Submit</button>
                    
              </div>
            </div>
          </div>
        </div>
        <!-- Feedback Modal end -->


      </section>
    </template>
    
    <script>
    import { API } from "@/api/api";

    export default {
      data() {
        return {
            isLoading: false,
            isShow: false,
            feedbackContent: '',
            errorMsg: '',
            token: null,
            modalErrorMsg: ''
        };
      },
      created() {
            this.token = this.$route.query.token;
            this.getResponse();
       },
      methods: {
        getResponse(){
                this.isLoading = true;
                API.get(`referral/cancelReferralSubscription?token=${this.token}`)
                .then(response => {
                    console.log(response, "After call generateReferral code");
                    this.isShow = true;
                    this.isLoading = false;
                }).catch(error => {
                    console.error("fail", error);
                    this.errorMsg = error.response.data.message;
                    this.isShow = false;
                    this.isLoading = false;
                    });
            },
            feedbackSubmit(){
              // Check if feedbackContent is empty
                  if (!this.feedbackContent.trim()) {
                    this.modalErrorMsg = 'Feedback cannot be empty';
                    return; // Prevent submission if feedback is empty
                  }
                const sendData = {
                    token: this.token,
                    feedback: this.feedbackContent
                }
                API.post(`feedback/sendFeedback`, sendData)
                .then(response => {
                    console.log(response, "send feedback data")
                    // Dismiss the modal after submission
                    const modal = document.getElementById('exampleModal');
                      if (modal) {
                        modal.classList.remove('show');
                        modal.style.display = 'none';
                        const backdrop = document.getElementsByClassName('modal-backdrop')[0];
                        if (backdrop) backdrop.remove();
                      }
                      // -------------------------
                    this.$router.push('/login');
                }).catch(error => {
                    console.error("fail", error);
                    this.modalErrorMsg = "Something Wrong.....";
                    this.isLoading = false;
                    });

            }
      },
    };
    </script>
    