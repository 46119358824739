<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="lds-ripple-content" v-if="isLoading"><div class="lds-ripple"><div></div><div></div></div></div>
      <section class="main-page choose-main">
        <div class="container">
          <div class="row choose-item-mn">
               <div class="col-md-6 col-sm-12 col-12">
                  <div class="header-logo">
                    <router-link to="/"><img src="assets/images/aanantya-logo.png" alt=""></router-link>
                  </div>
                  <div class="verify-inner-form">
                  <form @submit.prevent="submitForm">
                      <div class="form-header">
                      <h3>Create your Referral Code </h3>
                      
                      <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p>
                      </div>
                  
                      <div class="form-devider field-type">
                      <label for="name" class="form-label">WHAT INSTITUTION DO YOU BELONG TO OR WHAT IS YOUR WEBSITE</label>
                      <input type="text" class="form-control" id="name" placeholder="Enter Data" v-model.trim="formData.instituteOrWebsite"  />
                     </div>
  
                     <div class="form-devider field-type">
                      <label for="file" class="form-label">IF YOU HAVE AN ID PLEASE UPLOAD IT</label>
                      <input type="file" class="form-control" id="file" @change="handleFileUpload" />
                    </div>
  
                    <div class="form-devider field-type">
                      <label for="name" class="form-label">IF YOU DON'T HAVE AN ID, PLEASE EXPLAIN.</label>
                      <textarea class="form-control" id="name" placeholder="Enter Data" v-model.trim="formData.explanation"></textarea>
                     </div>
  
                     <p>
                      THANK YOU. CLICK HERE TO OBTAIN A SPECIAL UNIQUELY IDENTIFYING REFERRAL CODE THAT GIVES YOU THIRTY FREE DAYS. IT WILL BE EMAILED TO YOU RIGHT AWAY AS IT IS SECURELY GENERATED AND UNIQUE TO YOU.
                     </p>
  
                     <div class="form-devider">
                      <button type="submit" class="btn login-btn" :disabled="isDisabled">Submit</button>
                      </div>
  
                  </form>
                </div>
              </div>
          </div>
      </div>
      </section>
      </template>
    
    <script>
   import { API } from "@/api/api";
    
    export default {
      data() {
        return {
          isLoading: false,
          formData: {
            instituteOrWebsite: '',
            idFile : null,
            explanation: ''
          },
         formSubmitted: false, // Track form submission status
         errorMsg: '',
         isDisabled: false
        };
      },
      methods: {
        handleFileUpload(event) {
      const file = event.target.files[0]; // Get the uploaded file
      if (file) {
        // You can also upload the file to a server using Api or fetch
         this.uploadFileToServer(file);
       }
    },
    /* Function to upload file to the server using API or Fetch*/
    uploadFileToServer(file) {
      const formData = new FormData();
      formData.append('file', file);
      API.post('referral/uploadIdFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log('File uploaded!', response);
        this.formData.idFile = response.data.data.location;
      }).catch(error => {
        console.error('Error uploading file', error);
        this.errorMsg = "File not uploaded...";
      });
    },
    submitForm(){
       this.isDisabled = true;
        var head = {
            headers: {
                "x-access-token": JSON.parse(localStorage.getItem("Token"))
            }
          }
          API.post("referral/create", this.formData, head)
      .then(response => {
                console.log(response, "After call verify for referral code");
                this.$router.push('/login')
                this.isDisabled = false;
            }).catch(error => {
                  console.error("fail", error);
                  this.errorMsg = error.response.data.message;
                  this.isDisabled = false;
                  });
    },
      },
    };
    </script>
    