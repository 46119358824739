<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <div class="lds-ripple-content" v-if="isLoading"><div class="lds-ripple"><div></div><div></div></div></div>
	 <section class="main-page sign-in-page">
        <div class="container">
            <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt=""></div>
            <div class="row sign-in-form">
                <div class="col-md-4 col-sm-12 col-12">
                    <form @submit.prevent="submitForm">
                         <div class="form-header">
                            <h3>Login to Continue</h3>
                            <p>Use your email to sign in.</p>
                            <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p>
                         </div>
                          <div class="form-devider field-type">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" id="email" placeholder="email" v-model.trim="formData.email" />
							<span v-if="formSubmitted && !formData.email">Email is required</span>
							<span v-else-if="formSubmitted && !validateEmail(formData.email)">Please enter a valid email</span>
                          </div>
                          <div class="form-devider field-type">
                            <label for="password" class="form-label">Password</label>
                            <input type="password" class="form-control" id="password" placeholder="password" v-model.trim="formData.password" />
							<span v-if="formSubmitted && !formData.password">Password is required</span>
                          </div>
                          <!-- For referal code -->
                          <div class="form-devider field-type">
                            <label for="password" class="form-label">Referral Code</label>
                            <input type="password" class="form-control" id="referral_code" placeholder="Referral Code" v-model.trim="formData.referral_code" />
                          </div>
                          <div class="form-devider">
                            <button type="submit" class="btn login-btn">Login</button>
                          </div>
                          <div class="form-devider">
							<router-link to="/forgot"><a href="javascript:void(0);">Forgot Password</a></router-link>
                           </div>
                          <div class="form-devider">
                            <p class="mb-0 mt-1">Don't have an account? Sign up now!</p>
                            <router-link to="/signup"><a href="javascript.void(0);">Create an Account</a></router-link>
                          </div>

                          <div class="contentPage">
                            <router-link to="/terms-condition">Terms & Conditions & Privacy Policy</router-link>
                             || 
                             <router-link to="/about-us">About Us </router-link>
                          </div>


                    </form>
                    <div class="acknowledge_text">
                        <p><span>Acknowledgement:</span> 
                          To HRH Princess Catherine of Wales and to HRH Prince William of Wales - Thank you for your unlimited support and encouragement to make this Samskrit language teaching service a reality, and to help make Samskrit a living language again ~
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <beforeLoginChatbot></beforeLoginChatbot>
    </section>
  </template>
  
  <script>
  import { API } from "@/api/api";
  import beforeLoginChatbot from '../views/beforeLoginChatbot.vue';

  export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Login",
  components: {
      // eslint-disable-next-line vue/no-unused-components
      beforeLoginChatbot
  },
  data() {
    return {
      formData: {
        email: '',
        password: '',
        referral_code: ''
      },
      imageUrl: null,
      formSubmitted: false, // Track form submission status
      errorMsg: '',
      isLoading: false
    };
  },
	methods: {
		submitForm() {
      this.isLoading = true;
      this.formSubmitted = true; // Set form submitted status
      if (this.validateForm()) {
        console.log('Form submitted:', this.formData);
        const loginData = {
          user_email: this.formData.email,
          user_password: this.formData.password,
          referral_code: this.formData.referral_code
        }
         // Perform form submission (e.g., API call)
        API.post("auth/signin",  loginData)
      .then(response => {
                console.log(response, "After Login");
				localStorage.setItem("userInfo", JSON.stringify(response.data.data));
        localStorage.setItem("Token",JSON.stringify(response.data.data.token));
        this.isLoading = false;
        if((response.data.data.isSubscribed && !response.data.data.isTrialActivated) ||
        (!response.data.data.isSubscribed && response.data.data.isTrialActivated) || 
        (response.data.data.isReferralApplied && response.data.data.isActive)){
          this.$router.push('/dashboard');
        }
        else if(response.data.data.verificationPage && !response.data.data.isActive && !response.data.data.inUse){
          this.$router.push('/verify');
        }
        else{
          this.$router.push('/subscription');
        }
            }).catch(error => {
              console.error("fail", error);
                  this.errorMsg = error.response.data.message;
                  this.isLoading = false;
                  });
      } else {
        console.log('Form has errors');
        this.isLoading = false;
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validateForm() {
      return this.formData.email &&
             this.validateEmail(this.formData.email) &&
             this.formData.password
    },
	}
  };
  </script>
  