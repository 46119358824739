<template>
  <!-- Sign In page start here -->
  <section class="main-page sign-in-page">
        <div class="container">
            <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt="" /></div>
            <div class="row sign-in-form">
                <div class="col-md-5 col-sm-12 col-12">
                    <form @submit.prevent="submitForm">
                         <div class="form-header">
                            <h3>Create an Account</h3>
                            <p>Use your email to sign in.</p>
                            <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p>
                         </div>
                        
                         <div class="form-devider field-type">
                          <label for="name" class="form-label">Name</label>
                          <input type="text" class="form-control" id="name" placeholder="Your Name" v-model.trim="formData.fullName"  />
                          <span v-if="formSubmitted && !formData.fullName">Name is required</span>
                        </div>
                          <div class="form-devider field-type">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" id="email" placeholder="email" v-model.trim="formData.email" />
                            <span v-if="formSubmitted && !formData.email">Email is required</span>
                            <span v-else-if="formSubmitted && !validateEmail(formData.email)">Please enter a valid email</span>
                          </div>
                          
                          <div class="form-devider field-type">
                            <label for="password" class="form-label">Password</label>
                            <input type="password" class="form-control" id="password" placeholder="password" v-model.trim="formData.password" />
                            <span v-if="formSubmitted && !formData.password">Password is required</span>
                          </div>
                          <div class="form-devider field-type">
                            <label for="password" class="form-label">Confirm Password</label>
                            <input type="password" class="form-control" id="conpassword" placeholder="confirm password" v-model.trim="formData.confirmPassword" />
                            <!-- <span v-if="formSubmitted && !formData.confirmPassword">Confirm Password is required</span> -->
                            <span v-if="formSubmitted && !passwordsMatch">Passwords do not match</span>
                          </div>

                          <div class="form-devider field-type">
                            <label for="profilePhoto" class="form-label">Profile Photo</label>
                            <input type="file" class="form-control" id="profilepic" @change="handleFileUpload" accept="image/*" />
                            <div class="previewImage" v-if="imageUrl">
                              <img :src="imageUrl" alt="Uploaded Image"/>
                            </div>
                          </div>
                          <div class="form-devider field-type">
                            <label for="password" class="form-label">Are you a {{ selectedLanguage }} language scholar?</label>
                              <select  class="form-control" id="selectBox" v-model="formData.isScholar">
                                <option :disabled="true" value="null">Select a value</option>
                                <option :value="true">Yes</option>
                                <option :value="false">No</option>
                              </select>
                          </div>
                          <div class="form-devider field-type" v-if="formData.isScholar === false">
                            <label for="password" class="form-label">Are you a {{ selectedLanguage }} language expert author in subject matter expertise?</label>
                              <select  class="form-control" id="selectBox" v-model="formData.isExpert">
                                <option :disabled="true" value="null">Select a value</option>
                                <option :value="true">Yes</option>
                                <option :value="false">No</option>
                              </select>
                          </div>
                          <div class="form-devider field-type" v-if="formData.isExpert === false && formData.isScholar === false">
                            <label for="password" class="form-label">Are you a student of {{ selectedLanguage }} language in collegr or school?</label>
                              <select  class="form-control" id="selectBox" v-model="formData.isStudent">
                                <option :disabled="true" value="null">Select a value</option>
                                <option :value="true">Yes</option>
                                <option :value="false">No</option>
                              </select>
                          </div>
                          <div class="form-devider field-type" v-if="formData.isStudent === false && formData.isScholar === false && formData.isExpert === false">
                            <label for="password" class="form-label">Are you a {{ selectedLanguage }} language professor in college?</label>
                              <select  class="form-control" id="selectBox" v-model="formData.isProfessor">
                                <option :disabled="true" value="null">Select a value</option>
                                <option :value="true">Yes</option>
                                <option :value="false">No</option>
                              </select>
                          </div>
                          <div class="form-devider">
                            <button type="submit" class="btn login-btn">Register</button>
                          </div>
                          <div class="form-devider">
                            <p class="mb-0">Or Sign in with</p>
                          </div>
                          <!-- <div class="form-devider">
                            <button type="button" class="btn"><i class="fa-brands fa-google"></i> Google</button>
                          </div>
                          <div class="form-devider">
                            <button type="button" class="btn"><i class="fa-brands fa-apple"></i> Apple</button>
                          </div> -->
                          <div class="form-devider">
                            <p class="mb-0 mt-1">Already have an account? Login now!</p>
                            <router-link to="/login"><a href="javascript:void(0);">Enter The Account</a></router-link>
                          </div>
                          <div class="contentPage">
                            <router-link to="/terms-condition">Terms & Conditions & Privacy Policy</router-link>
                             || 
                             <router-link to="/about-us">About Us </router-link>
                          </div>
                    </form>
                </div>
            </div>
        </div>
        <beforeLoginChatbot></beforeLoginChatbot>
    </section>
    <!-- Sign In page end here -->
</template>

<script>
// @ is an alias to /src
import { API } from "@/api/api";
import beforeLoginChatbot from '../views/beforeLoginChatbot.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Signup",
  components: {
      // eslint-disable-next-line vue/no-unused-components
      beforeLoginChatbot
  },
  data() {
    return {
      formData: {
        email: '',
        password: '',
        fullName:'',
        confirmPassword:'',
        profilePicture: null,
        isScholar: null,
        isExpert:null,
        isStudent: null,
        isProfessor: null
      },
      imageUrl: null,
      formSubmitted: false, // Track form submission status
      errorMsg: '',
      selectedLanguage: 'Samskrit'
    };
  },
  computed: {
    passwordsMatch() {
      return this.formData.password === this.formData.confirmPassword;
    }
  },
  methods: {
    submitForm() {
      this.formSubmitted = true; // Set form submitted status
      if (this.validateForm()) {
        console.log('Form submitted:', this.formData);
        const signupData = {
          first_name: this.formData.fullName,
          user_email: this.formData.email,
          user_password: this.formData.password,
          profilePicture: this.formData.profilePicture,
          isScholar: this.formData.isScholar,
          isExpert: this.formData.isExpert,
          isStudent: this.formData.isStudent,
          isProfessor: this.formData.isProfessor
        }
         // Perform form submission (e.g., API call)
        API.post("auth/signup",  signupData)
      .then(response => {
                console.log(response, "After Registration");
                localStorage.setItem("userInfo", JSON.stringify(response.data.data));
                localStorage.setItem("Token",JSON.stringify(response.data.data.token));
                // Navigates to the '/' (verfication for referal code or subscription ) route
                (response.data.data.verificationPage) ? this.$router.push('/verify') : this.$router.push('/subscription');
                
            }).catch(error => {
                  console.error("fail", error);
                  this.errorMsg = error.response.data.message;
                  });
      } else {
        console.log('Form has errors');
        this.errorMsg = "Form has errors";
        this.isLoading = false;
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validateForm() {
      return this.formData.email &&
             this.validateEmail(this.formData.email) &&
             this.formData.password &&
             this.formData.fullName &&
             this.formData.confirmPassword &&
             this.passwordsMatch // Check passwords match;
    },
   
    // Profile picture upload code
    handleFileUpload(event) {
      const file = event.target.files[0]; // Get the uploaded file
      if (file) {
        this.previewImage(file); // Preview the selected image
        // You can also upload the file to a server using Api or fetch
         this.uploadFileToServer(file);
      }
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result; // Set the uploaded image as the source
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    },
    /* Function to upload file to the server using API or Fetch*/
    uploadFileToServer(file) {
      const formData = new FormData();
      formData.append('file', file);
      API.post('auth/uploadfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log('File uploaded!', response);
        this.formData.profilePicture = response.data.data.location;
      }).catch(error => {
        console.error('Error uploading file', error);
      });
    }
    
  },
  created() {
  },
  mounted() {
 
  },
};

</script>


